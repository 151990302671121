import React from "react";

import Layout from "../layouts/index";
import SEO from "../components/SEO";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

function SelectInput({ handleChange, type }) {
  const intl = useIntl();
  switch (type) {
    case "futes":
      return (
        <div className='form-group'>
          <label htmlFor='futes'>
            <FormattedMessage id='SolarCalc_heating?' />
          </label>
          <select
            required
            id='futes'
            name='futes'
            className='form-control'
            onChange={(e) => handleChange(e)}
          >
            <option value=''>
              {intl.formatMessage({ id: "Please_choose" })}
            </option>
            <option value='3.6'>
              {intl.formatMessage({ id: "SolarCalc_heating_opt1" })}
            </option>
            <option value='4'>
              {intl.formatMessage({ id: "SolarCalc_heating_opt2" })}
            </option>
            <option value='5'>
              {intl.formatMessage({ id: "SolarCalc_heating_opt3" })}/
            </option>
            <option value='1'>
              {intl.formatMessage({ id: "SolarCalc_heating_opt4" })}
            </option>
            <option value='0'>
              {intl.formatMessage({ id: "SolarCalc_heating_opt5" })}
            </option>
          </select>
        </div>
      );
    case "melegviz":
      return (
        <div className='form-group'>
          <label htmlFor='melegviz'>
            <FormattedMessage id='SolarCalc_hot_water?' />
          </label>
          <select
            required
            id='melegviz'
            name='melegviz'
            className='form-control'
            onChange={(e) => handleChange(e)}
          >
            <option value=''>
              {intl.formatMessage({ id: "Please_choose" })}
            </option>
            <option value='napkollektor'>
              {intl.formatMessage({ id: "SolarCalc_hw_opt1" })}
            </option>
            <option value='villanybojler'>
              {intl.formatMessage({ id: "SolarCalc_hw_opt2" })}
            </option>
            <option value='gazkazan'>
              {intl.formatMessage({ id: "SolarCalc_hw_opt3" })}
            </option>
            <option value='hoszivattyu'>
              {intl.formatMessage({ id: "SolarCalc_hw_opt4" })}
            </option>
            <option value='gazbojler'>
              {intl.formatMessage({ id: "SolarCalc_hw_opt5" })}
            </option>
          </select>
        </div>
      );
    default:
      return null;
  }
}

function ElAutoContent({ handleChange }) {
  const intl = useIntl();
  return (
    <div>
      {/*Mennyit autozik? */}
      <div className='form-row'>
        <div className='form-group col-md'>
          <label htmlFor='auto'>
            <FormattedMessage id='SolarCalc_car_dist?' />
          </label>
          <input
            className='form-control'
            placeholder='500'
            required
            onChange={(e) => handleChange(e)}
            required
            name='auto'
            id='auto'
            type='number'
          />
        </div>

        <div className='form-group col-md'>
          <label htmlFor='autotype'>
            <FormattedMessage id='SolarCalc_EV_type?' />
          </label>
          <select
            required
            id='autotype'
            name='autotype'
            className='form-control'
            onChange={(e) => handleChange(e)}
          >
            <option value=''>
              {intl.formatMessage({ id: "Please_choose" })}
            </option>
            <option value='147'>Hyundai Ioniq Elektro Style</option>
            <option value='148'>Mitsubishi i-MiEV</option>
            <option value='173'>VW e-Golf</option>
            <option value='174'>BMW i3 (94 Ah)</option>
            <option value='195'>Hyundai Kona Elektro (64 kWh) Trend</option>
            <option value='197'>Opel Ampera-e First Edition</option>
            <option value='203'>Renault Zoe Intens</option>
            <option value='205'>Nissan Leaf I Acenta (30 kWh)</option>
            <option value='221'>Nissan Leaf II Acenta</option>
            <option value='239'>Tesla Model S P90D</option>
            <option value='240'>Tesla Model X 100D</option>
            <option value='281'>Nissan e-NV200 Evalia</option>
          </select>
        </div>
      </div>
    </div>
  );
}

function Result({ c_result, c_result_kwh, error, formatNumber, elAuto, auto }) {
  const arak = [1100, 1200, 1400];
  const teljesitmenyek = [270, 300, 330];
  const intl = useIntl();

  let napelemek = teljesitmenyek.map((telj, index) => (
    <tr key={telj}>
      <td>{telj} Wp</td>
      <td>{Math.floor(c_result / telj) + 1} db</td>
      <td>~{Math.ceil((Math.floor(c_result / telj) + 1) * 1.7)} m²</td>
      <td>~{formatNumber(arak[index])} €/kWp </td>
      <td>
        ~
        {formatNumber(
          Math.ceil(
            ((telj / 1000) * (Math.floor(c_result / telj) + 1) * arak[index]) /
              10
          ) * 10
        )}{" "}
        €
      </td>
    </tr>
  ));

  return (
    <div id='calculated'>
      {error ? <span>{error}</span> : null}
      <div id='szukseglet' className='lead row my-3'>
        <div className='col-8 my-3 szukseglet_szoveg container'>
          <strong>
            <FormattedMessage id='SolarCalc_el_need1' />{" "}
            {formatNumber(c_result_kwh.toFixed(0))}{" "}
            <FormattedMessage id='SolarCalc_el_need2' />{" "}
          </strong>
        </div>
        <div className='py-1 col-4 turb_kep'>&nbsp;</div>
      </div>
      <div className='table-responsive-md'>
        <table className='table table-hover'>
          <tr>
            <th>
              <FormattedMessage id='SolarCalc_PV_type' />
            </th>
            <th>
              <FormattedMessage id='SolarCalc_PV_num' />
            </th>
            <th>
              <FormattedMessage id='SolarCalc_PV_area' />
            </th>
            <th>
              <FormattedMessage id='SolarCalc_PV_price' />
            </th>
            <th>
              <FormattedMessage id='SolarCalc_price' />
            </th>
          </tr>
          {napelemek}
        </table>
      </div>

      <div id='result_egyeb'>
        <h3 className='my-2'>
          <FormattedMessage id='SolarCalc_tip_intro' />
        </h3>
        <table className='table'>
          <tr className='tippek result_plus'>
            <td className='table_kep'>
              <div className='kis_kep tippek_kep'></div>
            </td>
            <td>
              <ul className='felsorolas'>
                <li>
                  <FormattedMessage id='SolarCalc_tip1' />
                </li>
                <li>
                  <FormattedMessage id='SolarCalc_tip2' />
                </li>
              </ul>
            </td>
          </tr>
          <tr className='zold result_plus'>
            <td className='table_kep'>
              <div className='kis_kep zold_kep'></div>
            </td>
            <td>
              <ul className='felsorolas'>
                {elAuto ? (
                  <li>
                    <FormattedMessage id='SolarCalc_car_savings' />
                    <strong>
                      {Math.round((auto * 12 * 0.16) / 10 / 100).toFixed(2)}
                    </strong>
                    <FormattedMessage id='SolarCalc_savings_end' />
                  </li>
                ) : null}
                <li>
                  <FormattedMessage id='SolarCalc_PV_savings' />
                  <strong>
                    {Math.round((c_result_kwh * 0.56) / 10) / 100}
                  </strong>
                  <FormattedMessage id='SolarCalc_savings_end' />
                  <br />
                  <small>
                    <FormattedMessage id='SolarCalc_car_comment1' /> &nbsp;
                    <a
                      className='text-dark'
                      href='https://www.adac.de/rund-ums-fahrzeug/tests/stromverbrauch-elektroautos-adac-test/?redirectId=quer.Elektroautos+Ecotest'
                    >
                      ADAC
                    </a>
                    &nbsp;
                    <FormattedMessage id='SolarCalc_car_comment2' />
                  </small>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>

      <h3 className='my-2'>
        <FormattedMessage id='Contact_us' />
      </h3>
      <form
        name='solarcalc'
        method='POST'
        method='post'
        action='/success'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <input type='hidden' name='form-name' value='NapelemKalkulátor' />

        <div className='row'>
          <div className='col-md-6 mb-3'>
            <input
              className='form-control'
              placeholder={intl.formatMessage({ id: "solarCalc_name" })}
              name='name'
              type='text'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <input
              className='form-control'
              placeholder={intl.formatMessage({ id: "solarCalc_email" })}
              name='email'
              type='text'
            />
          </div>
        </div>
        <input type='hidden' name='eredmeny' defaultValue={c_result} />
        <div className='text-center mb-4'>
          <button role='submit' className='button'>
            <FormattedMessage id='SolarCalc_consult!' />
          </button>
        </div>
      </form>
    </div>
  );
}

class SolarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elAuto: false,
      calculated: false,

      villany: "",
      gaz: "",
      villanytype: "kWh",
      gaztype: "m3",
      futes: "",
      lako: "",
      melegviz: "",

      auto: false,
      autotype: "",

      c_elec: "",
      c_futes: "",
      c_auto: "",
      c_hmv: "",
      c_result_kwh: "",
      c_result: "",
      name: "",
      email: "",

      error: "",
    };

    this.handleElAutoChange = this.handleElAutoChange.bind(this);
    this.calculate = this.calculate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  handleElAutoChange() {
    this.setState((prevState) => ({ elAuto: !prevState.elAuto }));
    if (this.state.calculated) {
      this.calculate();
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, function () {
      //console.log(this.state);
    });
    if (this.state.calculated) {
      this.calculate();
    }
  }

  calculate(e = null) {
    e.preventDefault();
    const {
      villany,
      gaz,
      villanytype,
      gaztype,
      futes,
      lako,
      melegviz,
      elAuto,
      autotype,
      auto,
    } = this.state;
    if (
      villany !== "" &&
      gaz !== "" &&
      villanytype !== "" &&
      gaztype !== "" &&
      futes !== "" &&
      lako !== "" &&
      melegviz !== ""
    ) {
      let elec =
        villanytype == "kwh" ? villany * 12 : (villany * 12 - 163) / 37.75;

      let c_futes =
        gaztype == "m3" ? (gaz * 34) / 3.6 / 1.11 : (gaz - 11674) / 11.5;

      c_futes = futes == "0" ? 0 : c_futes * (1 / futes);

      let c_auto = elAuto ? ((autotype * auto * 12) / 1000) * 1.13 : 0;

      let hmw = 0;
      if (
        melegviz != "napkollektor" &&
        melegviz != "gazbojler" &&
        melegviz != "gazkazan"
      ) {
        hmw = lako * 360 * (melegviz == "villanybojler" ? 2.5 : 1);
      }

      let result_kwh = elec + c_futes + c_auto + hmw;
      let result = Math.ceil(result_kwh / 1.1) + 1;

      this.setState({
        calculated: true,
        c_elec: elec,
        c_futes: c_futes,
        c_auto,
        c_hmv: hmw,
        c_result_kwh: result_kwh,
        c_result: result,
      });
    } else {
      this.setState({
        error: "hiba",
      });
    }
  }

  render() {
    const { calculated, elAuto } = this.state;
    return (
      <>
        <form acceptCharset='UTF-8'>
          <div className='row'>
            {/* Villanyfogyasztás */}
            <div className='col-md-6 mb-3'>
              <label htmlFor='villany'>
                <FormattedMessage id='SolarCalc_el_cons' />
              </label>
              <input
                className='form-control'
                placeholder='4000'
                required
                onChange={(e) => this.handleChange(e)}
                name='villany'
                type='number'
              />
              <div className='villanytype'>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='villanytype'
                    id='villanytype'
                    value='kWh'
                    defaultChecked
                    onChange={(e) => this.handleChange(e)}
                  />
                  <label className='form-check-label' htmlFor='villanytype'>
                    kWh
                  </label>
                </div>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='villanytype'
                    onChange={(e) => this.handleChange(e)}
                    id='villanytype'
                    value='Ft'
                  />
                  <label className='form-check-label' htmlFor='villanytype'>
                    Ft
                  </label>
                </div>
              </div>
            </div>

            {/* Gázfogyasztás */}
            <div className='col-md-6 mb-3'>
              <label htmlFor='gaz'>
                <FormattedMessage id='SolarCalc_gas_cons' />
              </label>
              <input
                className='form-control'
                placeholder='2000'
                required
                onChange={(e) => this.handleChange(e)}
                name='gaz'
                type='number'
                required
              />

              <div className='gaztype'>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='gaztype'
                    onChange={(e) => this.handleChange(e)}
                    id='gaztype'
                    value='m3'
                    defaultChecked
                  />
                  <label className='form-check-label' htmlFor='gaztype'>
                    m³
                  </label>
                </div>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='gaztype'
                    onChange={(e) => this.handleChange(e)}
                    id='gaztype'
                    value='Ft'
                  />
                  <label className='form-check-label' htmlFor='gaztype'>
                    Ft
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Mivel szeretnél fűteni? */}

          <SelectInput
            handleChange={this.handleChange}
            type='futes'
            {...this.props}
          />

          {/*Hányan laknak? */}
          <div className='form-group'>
            <label htmlFor='lako'>
              <FormattedMessage id='SolarCalc_num_resi?' />
            </label>
            <input
              required
              className='form-control'
              placeholder='4'
              required
              onChange={(e) => this.handleChange(e)}
              name='lako'
              id='lako'
              type='number'
            />
          </div>

          {/* Melegvíz melegítése? */}
          <SelectInput
            handleChange={this.handleChange}
            type='melegviz'
            {...this.props}
          />

          {/* El-autohoz is? */}
          <div className='custom-control custom-checkbox mb-3'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='elauto'
              onChange={(e) => this.handleElAutoChange(e)}
              checked={elAuto}
            />
            <label className='custom-control-label' htmlFor='elauto'>
              <FormattedMessage id='SolarCalc_EV?' />
            </label>
          </div>

          {elAuto ? <ElAutoContent handleChange={this.handleChange} /> : null}

          {!calculated ? (
            <div className='form-group'>
              <div className='mt-2 text-center'>
                <button
                  onClick={(e) => this.calculate(e)}
                  className='button w-50'
                >
                  <FormattedMessage id='Calculate' />
                </button>
              </div>
            </div>
          ) : (
            <hr />
          )}
        </form>

        {calculated ? (
          <Result formatNumber={this.formatNumber} {...this.state} />
        ) : null}
      </>
    );
  }
}

const SolarcalcPage = (props) => {
  const { pathname } = props.location;
  return (
    <Layout path={pathname}>
      <SEO title='Solar Calculator' />
      <div className='form container my-5'>
        <div className='form-header'>
          <h3 className='leiras pt-3'>
            <FormattedMessage id='SolarCalc_intro1' />
          </h3>
          <span className='lead'>
            <FormattedMessage id='SolarCalc_intro2' />
          </span>
          <ul className='list-group feltetelezesek mb-3'>
            <li className='list-group-item'>
              <FormattedMessage id='SolarCalc_intro3' />
            </li>
            <li className='list-group-item'>
              <FormattedMessage id='SolarCalc_intro4' />
            </li>
          </ul>
        </div>
        <hr />
        <SolarForm {...props} />
      </div>
    </Layout>
  );
};

export default SolarcalcPage;
